<template>
  <div>
    <div>
      <CardTitle class="memories" :text="getEmsDataPropertyText('show_recorded_video_title_page4')" />
      <StartVideoPlayer class="watch-video">
        <video class="full-screen-video" ref="my_vlog" :src="video_src" @ended="showPlayButton" />
        <div class="watch-play-container" ref="start_button" @click="play()">
          <div class="btn-start">
            <i class="material-icons">play_arrow</i>
          </div>
        </div>
      </StartVideoPlayer>
    </div>
    <div class="watch-video__bg">
      <div class="continium-logo">
        <img :src="require('@/assets/img/logo-continium-positief@2x.png')" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import CardTitle from "@/components/CardTitle.vue";
import StartVideoPlayer from "@/components/StartVideoPlayer.vue";
import { getLink } from "@/BackendClient.js";

export default {
  name: "start",
  components: {
    CardTitle,
    StartVideoPlayer
  },
  data: () => ({
    video_src: ""
  }),
  mounted() {
    document.documentElement.style['overflow-y'] = 'scroll'
    if (this.$route.query.video_id) {
      let self=this
      getLink(this.$route.query.video_id).then(function(video_link) {
        self.video_src = video_link;
      });
    }
  },
  methods: {
    play() {
      this.$refs.my_vlog.play();
      this.$refs.start_button.style.display = "none";
    },
    showPlayButton() {
      this.$refs.start_button.style.display = "block";
    }
  }
};
</script>
<style lang="scss" scoped>

.btn-start {
  background: #FF5800;
  width: 80px;
  height: 80px;
  border-radius: 40px;
  margin: auto;
  margin-top: 22%;
  cursor: pointer;

  &:hover {
    background: #d94d03;
  }

  i {
    color: #FFFFFF;
    font-size: 40px;
    padding-top: 18px;
  }
}

.watch-video__bg {
  bottom: 0;
  position: relative;
  width: 100%;
  padding: 90px 0;
}

.continium-logo {
  position: relative;
  width: 35%;
  margin: 0 auto;
}

.watch-video {
  position: relative;
  margin-top: 17vh;
}

.watch-play-container {
  z-index: 2;
  position: absolute;
  float: left;
  margin: 0 auto;
  width: 95%;
  top: 0;
}

.full-screen-video {
  object-fit: cover;
  background-color: white;
}

@media screen and (max-width: 770px) {
  .video-player {
    margin-left: 20px;
    margin-right: 20px;
  }
}

@media only screen and (max-device-width: 480px) {
  .start_button{
    zoom: 0.5;
  }
  .video-player {
    top: 32%;
  }
  .video-player {
    padding: 4vw;
  }
}

</style>

<style lang="scss">

body {
  background-image: url("../assets/img/share-email-2-bg.png");
  background-size: cover;
}

@media screen and (max-width: 480px) {
  .instructions-card-title-container {
    top: -5vw !important;
    p {
      font-size: 7vw !important;
    }
  }
}

</style>